import { graphql } from "gatsby"
import { Link, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Testimonials2 from "../components/swiper/testimonials-new"
//components
import PartnerSwiper from "../components/swiper/swiper-partner.js"

import Modal from "../components/modal/quickbase-data-modal"

import {
  HiOutlineMagnifyingGlassCircle,
  HiOutlineDocumentCheck,
} from "react-icons/hi2"
import { GrDocumentPerformance } from "react-icons/gr"
import {
  MdErrorOutline,
  MdOutlineIntegrationInstructions,
  MdOutlineNotificationImportant,
} from "react-icons/md"
import { CiSettings } from "react-icons/ci"
import { FiUserCheck } from "react-icons/fi"
import { FaListCheck } from "react-icons/fa6"
import { LuMonitorCheck } from "react-icons/lu"
import { GoProjectSymlink } from "react-icons/go"
import { TbCheckupList } from "react-icons/tb"
import { BsGraphUpArrow } from "react-icons/bs"

import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import Hero from "../components/hero/hero-application-health-audit.js"
import QuickbaseSections from "../components/quick-base-solutions/QuickbaseSections"

import app1 from "../images/new-images/app1.jpg"
import app2 from "../images/new-images/app2.jpg"
import app3 from "../images/new-images/app3.jpg"
import app4 from "../images/new-images/app4.jpg"
import app5 from "../images/new-images/app5.jpg"

class ApplicationHealthAuditPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Hero />}
      >
        <Seo
          title="Application Health Audit | Quandary Consulting Group"
          description="Evaluate the health of your Quickbase applications: the Custom Code, Infrastructure, Architecture, Integrations, Permissions, and Processes."
        />
        <div id="main">
          <LazyLoadComponent>
            <section id="logos-wrapper" className="">
              <LazyLoadComponent>
                <PartnerSwiper />
              </LazyLoadComponent>
            </section>

            <LazyLoadComponent>
              <section
                id="it-consulting"
                className="it-consulting bg-white"
                style={{ padding: "0rem 0" }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 col-md-8 mx-auto text-center">
                      <h2 className="mb-2 mb-2 font-weight-medium">
                        Maximize the Value of Your Quickbase Applications
                      </h2>
                      <p className="mt-4">
                        Just like your car needs maintenance over time, your
                        applications need regular tuning and improvements to
                        deliver the maximum value to your organization.
                      </p>
                    </div>
                  </div>
                  <div
                    className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
                      marginTop: "3rem",
                    }}
                  >
                    <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-5">
                      <h3>Comprehensive App Assessment </h3>
                      <p className="mt-4">
                        We evaluate the following: Custom Code, Infrastructure,
                        Architecture, Integrations, Permissions, and Processes.
                        If there are any issues or pain points, we share a
                        report highlighting those weaknesses. And we work with
                        you to prioritize improvements that help your
                        applications scale.
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex mobile-hidden-img"
                        src="../images/new-images/app1.jpg"
                        alt="Security Audit"
                        layout="constrained"
                        loading="lazy"
                      />
                      <img src={app1} className="img-fluid mobile-appear-img" />
                    </div>
                  </div>
                  <div className="row align-items-center mt-3">
                    <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex mobile-hidden-img"
                        src="../images/new-images/app2.jpg"
                        alt="Training and Support"
                        layout="constrained"
                        loading="lazy"
                      />
                      <img src={app2} className="img-fluid mobile-appear-img" />
                    </div>
                    <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                      <h3>Improve Performance. Reduce Costs.</h3>
                      <p className="mt-4">
                        The audit shows you what changes or improvements your
                        team should prioritize to drive the highest ROI on your
                        Quickbase applications. We also look for opportunities
                        to reduce technical debt from poorly constructed or
                        unfinished applications and increase batch requests to
                        optimize API call volume, lowering your costs where
                        possible.
                      </p>
                    </div>
                  </div>
                  <div
                    className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                  >
                    <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-5">
                      <h3>Seamless Roadmap Integration</h3>
                      <p className="mt-4">
                        You have a roadmap for business improvements. We work
                        with you to prioritize those implementations along the
                        critical health issues of your applications. That way
                        you still make progress toward your goals while
                        optimizing your existing applications.
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex mobile-hidden-img"
                        src="../images/new-images/app3.jpg"
                        alt="Custom, Hands-On Testing"
                        layout="constrained"
                        loading="lazy"
                      />
                      <img src={app3} className="img-fluid mobile-appear-img" />
                    </div>
                  </div>
                  <div className="row align-items-center mt-3">
                    <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex mobile-hidden-img"
                        src="../images/new-images/app4.jpg"
                        alt="Access Control Done Right"
                        layout="constrained"
                        loading="lazy"
                      />
                      <img src={app4} className="img-fluid mobile-appear-img" />
                    </div>
                    <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                      <h3>“A la Carte” Approach</h3>
                      <p className="mt-4">
                        We can conduct the audit all at once or focus on
                        specific areas depending on your priorities. Following
                        the agile methodology for development, we will highlight
                        the most critical areas that drive the most impact in
                        your organization and go from there.
                      </p>
                    </div>
                  </div>
                  <div
                    className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
                  >
                    <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-5">
                      <h3>Consistency Across Applications</h3>
                      <p className="mt-4">
                        When multiple developers are involved, they tend to
                        build applications in their own way, leading to
                        inconsistencies in your Quickbase applications. We
                        evaluate your applications to ensure consistent
                        branding, data structures, and UX across your entire
                        Quickbase ecosystem.
                      </p>
                    </div>
                    <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                      <StaticImage
                        placeholder="blurred"
                        className="rounded d-flex mobile-hidden-img"
                        src="../images/new-images/app5.jpg"
                        alt="Advanced Technical Design Documentation"
                        layout="constrained"
                        loading="lazy"
                      />
                      <img src={app5} className="img-fluid mobile-appear-img" />
                    </div>
                    {/* </div>
                 <div className="row align-items-center mt-3">
                <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex"
                    src="../images/new-images/tech-debt.jpg"
                    alt="Tech Debt Assessment and Clean-Up"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                  <h3>Tech Debt Assessment and Clean-Up</h3>
                  <p className="mt-4">
                    We’ll find Quick-Fix Tech Debt and offer remediation
                    suggestions. For example: Removing deprecated or unconnected
                    tables, fields, reports, dashboards, homepages, forms, and
                    roles
                  </p>
                  <p className="mt-4">
                    <b>Does not include</b>: New feature requests or
                    changes/additions to existing structures/workflows.
                  </p>
                </div>
              </div>
              <div
                className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
                style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
              >
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile py-5">
                  <h3>Rollout and Go-Live Support</h3>
                  <p className="mt-4">
                    We provide end-user support for UI interaction and access
                    during your new application and feature rollouts.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex"
                    src="../images/new-images/go-live-support.jpg"
                    alt="Rollout and Go-Live Support"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="row align-items-center mt-3">
                <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex"
                    src="../images/new-images/data-import.jpg"
                    alt="Legacy Data Import"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                  <h3>Legacy Data Import</h3>
                  <p className="mt-4">
                    Get support importing clean legacy data into your new
                    applications using the native Quickbase import
                    functionality.
                  </p>
                </div>*/}
                  </div>
                  <div className="hero-button text-center">
                    <Link
                      to="/contact/"
                      className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                      style={{ marginTop: "auto" }}
                    >
                      Check Your Apps
                    </Link>
                  </div>
                </div>
              </section>
            </LazyLoadComponent>
            <LazyLoadComponent>
              <section
                className="quick-base-glance"
                style={{ padding: "3rem 0" }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h2 className="text-center mb-5">
                        Our 10-Step Process for Running Your Application Health
                        Audit
                      </h2>
                      {/* <p className="text-center">Here’s what we cover:</p> */}
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <HiOutlineMagnifyingGlassCircle />
                      <h4 className="my-4">1. Discovery</h4>
                      <p>
                        We perform a deep analysis of your applications,
                        evaluating the logic, structure, workflows,
                        functionality, processes, and more.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <GrDocumentPerformance />
                      <h4 className="my-4">2. Performance Optimization</h4>
                      <p>
                        We discover weak points in your application to optimize
                        for performance, leading to enhanced user experience and
                        efficiency.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <MdErrorOutline />
                      <h4 className="my-4">3. Error Reduction</h4>
                      <p>
                        We detect and address potential sources of errors or
                        bugs (unnecessary/broken tables, fields, reports, etc.),
                        ensuring smoother operation.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <CiSettings />
                      <h4 className="my-4">4. Tune-Up</h4>
                      <p>
                        We review filters, columns, searchable fields, tables,
                        and calculations to identify and remove redundancies.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <BsGraphUpArrow />
                      <h4 className="my-4">5. Scalability Assessment</h4>
                      <p>
                        We assess the application's ability to scale in response
                        to business growth, safeguarding future usability and
                        relevance.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <FiUserCheck />
                      <h4 className="my-4">6. User Experience Evaluation</h4>
                      <p>
                        We gather direct feedback on your applications from
                        users, identifying weak points in the UX and suggesting
                        improvements that lead to higher user adoption rates and
                        satisfaction.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <MdOutlineIntegrationInstructions />
                      <h4 className="my-4">7. Integration Check</h4>
                      <p>
                        We review how well the application integrates with other
                        systems, suggesting improvements if needed.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <MdOutlineNotificationImportant />
                      <h4 className="my-4">8. Notification Review</h4>
                      <p>
                        We audit how your applications notify your team to
                        reduce the noise and ensure the right team members
                        receive the right updates.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <HiOutlineDocumentCheck />
                      <h4 className="my-4">9. Documentation Review</h4>
                      <p>
                        We evaluate the quality and completeness of
                        documentation for easier maintenance and updates.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 mt-5 text-center">
                      <FaListCheck />
                      <h4 className="my-4">10. Future Roadmap Suggestions</h4>
                      <p>
                        We recommend future enhancements and upgrades to keep
                        the application relevant and effective while accounting
                        for your improvement wish list.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-5">
                  <div className="hero-button text-center">
                    <Link
                      to="/contact/"
                      className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                      style={{ marginTop: "auto" }}
                    >
                      Check Your Apps
                    </Link>
                  </div>
                </div>
              </section>
            </LazyLoadComponent>
            <LazyLoadComponent>
              <div
                className="text-center intro py-5 bg-gradient"
                style={{ padding: "5rem 0" }}
                id=""
              >
                {/* <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h5 className="text-light-blue text-uppercase font-weight-bold m-0">
                        Reviews
                      </h5>
                      <h2 className="my-2 text-white mt-4">
                        What Our Clients Say
                      </h2>
                    </div>
                  </div>
                </div> */}
                <Testimonials2 />
              </div>
            </LazyLoadComponent>
            <LazyLoadComponent>
              <section
                className="bg-white quandary-connect-glance"
                style={{ padding: "3rem 0" }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <h2>
                        Do Your Quickbase Applications Need a Health Assessment?
                      </h2>
                      <p>Our health assessment is right for you if:</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 mt-5 text-center">
                      <LuMonitorCheck />
                      <h3 className="h5 my-4" style={{ fontWeight: "bold" }}>
                        You Have Existing Quickbase Applications
                      </h3>
                      <p>
                        The Application Health Audit analyzes existing Quickbase
                        apps specifically and you’re experiencing a slowdown in
                        performance.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-5 text-center">
                      <GoProjectSymlink />
                      <h3 className="h5 my-4" style={{ fontWeight: "bold" }}>
                        You’re About to Start a New Project
                      </h3>
                      <p>
                        Running the application health audit alongside existing
                        Quickbase consulting services speeds up development time
                        and ensures your consulting team focuses on the highest
                        priority items.
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-5 text-center">
                      <TbCheckupList />
                      <h3 className="h5 my-4" style={{ fontWeight: "bold" }}>
                        Your Last Health Application Check Was 12+ Months Ago
                      </h3>
                      <p>
                        You should run a health audit once a year, between large
                        development stints, to ensure your applications continue
                        to provide value, remain secure, and scale with your
                        business.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </LazyLoadComponent>
            <section
              className="bg-indigo"
              style={{
                paddingTop: "5rem",
                paddingBottom: "5rem",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 mx-auto text-center text-left-mobile">
                    <div>
                      <h2 className="text-white">
                        Maximize Your App and Team Performance
                      </h2>
                      <p className="text-white">
                        Are you getting the most out of your Quickbase apps?{" "}
                        <br /> Get a comprehensive evaluation of your
                        application stack and find out today!
                      </p>

                      <Link
                        to="/contact/"
                        className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                        style={{ marginTop: "auto" }}
                      >
                        Check Your Apps
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default ApplicationHealthAuditPage

export const QuickbaseSecurityAuditPagePageQuery = graphql`
  query ApplicationHealthAuditPagePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
